import useLanguage from 'hooks/useLanguage';

export const searchParams = {
	plan: 'plan', // ?plan=
	pl: {
		section: {
			name: 'sekcja',
			contactForm: 'formularz-kontaktowy',
			calculatorSection: 'kalkulator-korzysci-finteq',
			declareCandidateAnimator: 'zglaszam-kandydata-na-animatora'
		},
		subject: {
			name: 'temat',
			enterprise: 'enterprise',
			education: 'edukacja'
		}
	},
	en: {
		section: {
			name: 'section',
			contactForm: 'contact-form',
			calculatorSection: 'finteq-benefit-calculator',
			declareCandidateAnimator: 'declare-a-candidate-animator'
		},
		subject: {
			name: 'subject',
			enterprise: 'enterprise',
			education: 'education'
		}
	}
};

export const getSearchParam = search => {
	if(!search || typeof search !== 'string') return '';
	return search.split('=')[1];
};

export const getSearchSecondParam = search => {
	if(!search || typeof search !== 'string') return '';
	return search.split('=')[2];
};

// TODO: refactor - use useSearchParam
// const queryParams = new URLSearchParams(location.search);
export default function useSearchParams() {
	const { language, location } = useLanguage();

	return {
		language,
		location,
		section: searchParams[language].section.name,
		subject: searchParams[language].subject.name,
		searchLangParams: searchParams[language]
	};
}
