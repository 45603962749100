import EsgPage from 'components/pages/esg-compliant-supply-chain-financing/EsgPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const Index = () => (
	<div>
		<Seo
			title='ESG w łańcuchu dostaw czyli zarządzanie płynnością w sposób zrównoważony'
			keywords='Esg, zielone finansowanie, esg w banku, ryzyka esg, zarządzanie, etyczny łańcuch dostaw, dekarbonizacja, esg due dilligence, esg w łańcuchu'
			description='Finansowanie dostawców kapitałem zewnętrznym nie spełnia podstawowych przesłanek EGS tj. nie ogranicza śladu węglowego wytworzonych produktów i usług. Przeciwnie, zwiększa poziom emisji gazów cieplarnianych na każdą sztukę produktu zawsze, gdy w procesie zakupu, wytworzenia, sprzedaży lub inkasa należności przedsiębiorstwo posiłkuje się kapitałem zewnętrznym pochodzącym z instytucji finansowych.'
			lang='pl-PL'
			locale='pl_PL'
		/>

		<EsgPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
